import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle'
import BlogSingle from '../../components/BlogDetails/BlogDetails';
import { useParams } from 'react-router-dom'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import blogs from '../../api/blogs'

const BlogDetailsFull = () => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)

    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={BlogDetails?.title} pagesub={'Blog'}  />
            <BlogSingle blLeft={'hidden'} blRight={'col-span-8'} gClass={'grid-cols-1'}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default BlogDetailsFull;